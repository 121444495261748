header#myHeader {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(203,203,204,1) 58%, rgba(148,148,149,1) 100%);
    }
    .btn-main {
    box-shadow:inset 0px 1px 0px 0px #ededed;
    background:linear-gradient(to bottom, #ededed 5%, #979798 100%);
    background-color:#ededed;
    border-radius:2px;
    border:1px solid #dcdcdc;
    display:inline-block;
    cursor:pointer;
    color:#000000 !important;
    font-weight:bold;
    padding:6px 24px;
    text-decoration:none;
    }
    footer.footer-light .subfooter .social-icons span i {
    background: #000;
    }
    .navbar .navbar-item .lines {
    border-bottom: 2px solid #000;
    }
    .jumbotron h1{
    color: #fff;
    }
    .jumbotron h2 .color {
    color: #fff;
    }
    .jumbotron p{
    color: #fff;
    }
    .jumbotron .btn-main{
    text-transform: uppercase;
    font-size: 24px !important;
    padding: 0px 40px;
    }
    .navbar{
    border-bottom: 1px solid #000;
    }
    .feature-box.style-3{
    padding:20px;
    }
    .feature-box.style-3 i{
    background: none;
    padding:0px;
    height: 100%;
    width: 30%;
    float: left;
    }
    .feature-box.f-boxed.style-3 {
    background: rgb(251,252,252);
    background: linear-gradient(180deg, rgba(251,252,252,1) 0%, rgba(153,153,155,1) 100%);
    }
    .feature-box.f-boxed.style-3 i.wm{
    display: none;
    }
    .feature-box.style-3 p{
    color: #000;
    font-weight: 500;
    font-size: 14px;
    }
    .feature-box.style-3 .text{
    padding-left: 30%;
    }
    .feature-box.style-3 .icon_wallet:before{
    content: '';
    background: url(../img/money.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    .feature-box.style-3 .icon_key_alt:before{
    content: '';
    background: url(../img/lucky.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    .feature-box.style-3 .social_share:before{
    content: '';
    background: url(../img/share.svg);
    width: 100px;
    height: 100px;
    display: block;
    background-position: 0;
    background-repeat: no-repeat;
    }
    footer.footer-light #form_subscribe #btn-subscribe i{
    box-shadow: inset 0px 1px 0px 0px #ededed;
    background: linear-gradient(to bottom, #ededed 5%, #979798 100%);
    background-color: #ededed;
    display: inline-block;
    cursor: pointer;
    color: #000000 !important;
    font-weight: bold;
    text-decoration: none;
    border-top: #000 solid thin;
    border-right: #000 solid thin;
    border-bottom: #000 solid thin;
    padding: 4px;
    }
    .breadcumb:before {
        display: none;
    }
    .breadcumb.l-vh{
        margin-top: 0px;
        padding-top: 80px;
    }
    .jumbotron p {
        font-weight: 600;
    }
    .table>:not(caption)>*>* {
        background-color: rgba(0,0,0,0.5);
    }
    @media only screen and (max-width: 1199px){
        .navbar.white a {
        color: #000;
    }}

/* Animacion */
img.lazy.img-fluid {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}
@keyframes float {
  0% {
transform: translatey(0px);
}
50% {
transform: translatey(-20px);
}
100% {
transform: translatey(0px);
}
}

.jumbotron h1, .jumbotron h2 .color, .jumbotron p {
    color: #fff;
    font-weight: 500;
}
.field-set label {
    font-weight: 800;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #000;
}
.navbar .navbar-item.active .lines {
        width: 90%;
        transition: .7s;
}


ul.text-white{
    max-height: 500px;
    overflow-y: scroll;
}

ul.text-white::-webkit-scrollbar{
    background: black;
}